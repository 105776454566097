const tarefaStore = {
    state() {
        return {
            tarefas: '',
            categoria: '',
            secao: '',
            codigo_de_barras: '',
            validade: '',
        };
    },
    mutations: {
        setTarefas(state, payload) {
            state.tarefas = payload;
        },
        setCategoria(state, payload) {
            state.categoria = payload;
        },
        setSecao(state, payload) {
            state.secao = payload;
        },
        setCodigo(state, payload) {
            state.codigo_de_barras = payload;
        },
        setValidade(state, payload) {
            state.validade = payload;
        },
    },
    actions: {
        setTarefas(context, payload) {
            context.commit('setTarefas', payload);
        },
        setCategoria(context, payload) {
            context.commit('setCategoria', payload);
        },
        setSecao(context, payload) {
            context.commit('setSecao', payload);
        },
        setCodigo(context, payload) {
            context.commit('setCodigo', payload);
        },
        setValidade(context, payload) {
            context.commit('setValidade', payload);
        },
    },
    getters: {
        getTarefas(state) {
            return state.tarefas;
        },
        getCategorias(state) {
            let tarefas = state.tarefas;

            return tarefas.reduce(function (key, element) {
                key[element.categoria_app_nivel_1] = key[element.categoria_app_nivel_1] || [];
                key[element.categoria_app_nivel_1].push(element.categoria_app_nivel_1);
                return key;
            }, Object.create(null));
        },
        getCategoriasSub(state) {
            let prod = state.tarefas;

            // let filtrados = prod.filter((element) => {
            //     return element.secao == state.secao;
            // });

            return prod.reduce(function (key, element) {
                key[element.categoria_app_nivel_2] = key[element.categoria_app_nivel_2] || [];
                key[element.categoria_app_nivel_2].push(element);
                return key;
            }, Object.create(null));
        },
        getTarefasCategoria(state) {
            let prod = state.tarefas;

            let filtrados = prod.filter((element) => {
                return element.categoria_app_nivel_2 == state.categoria;
            });

            return filtrados.reduce(function (key, element) {
                key[element.categoria_app_nivel_2] = key[element.categoria_app_nivel_2] || [];
                key[element.categoria_app_nivel_2].push(element);
                return key;
            }, Object.create(null));
        },
        getTotalTarefas(state) {
            return state.tarefas.length;
        },
        getCategoria(state) {
            return state.categoria;
        },
        getSecao(state) {
            return state.secao;
        },
        getTarefa(state) {
            let prod = state.tarefas;

            let filtrados = prod.filter((element) => {
                return element.codigo_de_barras == state.codigo_de_barras && element.data_validade == state.validade;
            });

            return filtrados[0];
        },
    },
};

export default tarefaStore;
