const tarefaPlanilhaStore = {
    state() {
        return {
            tarefasPlanilha: '',
            categoriaPlanilha: '',
            secaoPlanilha: '',
            data_bloco: '',
            codigo_de_barras_planilha: '',
            validade_planilha: '',
        };
    },
    mutations: {
        setTarefasPlanilha(state, payload) {
            state.tarefasPlanilha = payload;
        },
        setCategoriaPlanilha(state, payload) {
            state.categoriaPlanilha = payload;
        },
        setSecaoPlanilha(state, payload) {
            state.secaoPlanilha = payload;
        },
        setCodigoPlanilha(state, payload) {
            state.codigo_de_barras_planilha = payload;
        },
        setValidadePlanilha(state, payload) {
            state.validade_planilha = payload;
        },
        setDataBloco(state, payload) {
            state.data_bloco = payload;
        },
    },
    actions: {
        setTarefasPlanilha(context, payload) {
            context.commit('setTarefasPlanilha', payload);
        },
        setCategoriaPlanilha(context, payload) {
            context.commit('setCategoriaPlanilha', payload);
        },
        setSecaoPlanilha(context, payload) {
            context.commit('setSecaoPlanilha', payload);
        },
        setCodigoPlanilha(context, payload) {
            context.commit('setCodigoPlanilha', payload);
        },
        setValidadePlanilha(context, payload) {
            context.commit('setValidadePlanilha', payload);
        },
        setDataBloco(context, payload) {
            context.commit('setDataBloco', payload);
        },
    },
    getters: {
        getTarefasPlanilha(state) {
            return state.tarefasPlanilha;
        },
        getCategoriasPlanilha(state) {
            let tarefas = state.tarefasPlanilha;

            let filtrados = tarefas.filter((element) => {
                return element.data_bloco == state.data_bloco;
            });

            return filtrados.reduce(function (key, element) {
                key[element.categoria_app_nivel_1] = key[element.categoria_app_nivel_1] || [];
                key[element.categoria_app_nivel_1].push(element.categoria_app_nivel_1);
                return key;
            }, Object.create(null));
        },
        getCategoriasSubPlanilha(state) {
            let prod = state.tarefasPlanilha;

            let filtrados = prod.filter((element) => {
                return element.data_bloco == state.data_bloco;
            });

            return filtrados.reduce(function (key, element) {
                key[element.categoria_app_nivel_2] = key[element.categoria_app_nivel_2] || [];
                key[element.categoria_app_nivel_2].push(element);
                return key;
            }, Object.create(null));
        },
        getTarefasCategoriaPlanilha(state) {
            let prod = state.tarefasPlanilha;

            let filtrados = prod.filter((element) => {
                return (
                    element.categoria_app_nivel_2 == state.categoriaPlanilha && element.data_bloco == state.data_bloco
                );
            });

            return filtrados.reduce(function (key, element) {
                key[element.categoria_app_nivel_2] = key[element.categoria_app_nivel_2] || [];
                key[element.categoria_app_nivel_2].push(element);
                return key;
            }, Object.create(null));
        },
        getTotalTarefasPlanilha(state) {
            return state.tarefasPlanilha.length;
        },
        getCategoriaPlanilha(state) {
            return state.categoriaPlanilha;
        },
        getSecaoPlanilha(state) {
            return state.secaoPlanilha;
        },
        getTarefaPlanilha(state) {
            let prod = state.tarefasPlanilha;
            let filtrados = prod.filter((element) => {
                return (
                    element.codigo_de_barras == state.codigo_de_barras_planilha &&
                    element.data_bloco == state.data_bloco
                );
            });

            return filtrados[0];
        },
        getTarefasDataBloco(state) {
            let prod = state.tarefasPlanilha;
            return prod.reduce(function (key, element) {
                key[element.data_bloco] = key[element.data_bloco] || [];
                key[element.data_bloco].push(element);
                return key;
            }, Object.create(null));
        },
        getDataBloco(state) {
            return state.data_bloco;
        },
    },
};

export default tarefaPlanilhaStore;
