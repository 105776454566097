<template>
    <transition name="fade" appear>
        <div class="popUp">
            <div class="tela-mensagem">
                <div class="error">
                    <img class="rotate-center" src="../assets/img/refresh.svg" alt="imagem de check" />
                </div>
                <div class="error-msg">
                    <p>{{ msgSync }}</p>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        msgSync: {
            type: String,
        },
    },

    methods: {
        closePopUp() {
            this.$emit('closePopUp');
        },
    },
};
</script>

<style scoped>
.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 5%;
    top: 15%;
    bottom: 15%;
    right: 5%;
    margin: 18px;
    background-color: #fff;
    border-radius: 6px;
}

.error {
    padding: 10px 20px;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.error img {
    width: 90px;
}

.rotate-center {
    -webkit-animation: rotate-center 1.5s linear infinite;
    animation: rotate-center 1.5s linear infinite;
}

.error-msg {
    padding: 10px;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    text-align: center;
}

.error-msg p {
    margin-top: 10px;
}

.error-msg p {
    font-size: 16px;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (min-width: 600px) {
    .error-msg p {
        font-size: 22px;
    }

    .error img {
        width: 120px;
    }
}
</style>
